<template>
    <div class="mb-4" v-loading="isLoadingChart">
        <highcharts class="mt-4 mr-1" :options="revenueByDateAccordingRechargeTime.options"></highcharts>
        <div class="text-center d-md-flex d-flex flex-row flex-md-row align-content-center justify-content-center">
            <div class="mr-2 mr-md-4 mb-2 mb-md-0">
                <el-button type="primary" size="medium" @click="getRevenueByDateAccordingRechargeTime">
                    <i class="el-icon-refresh"></i> <span class="d-none d-md-inline-block">Refresh</span>
                </el-button>
            </div>
            <div class="mr-0 mr-md-4">
                <el-radio-group v-model="typeOfChart" 
                                size="medium" 
                                class="mr-3 d-none d-md-block"
                                @change="onChangeTypeOfChart">
                    <el-radio-button :label="0" class="mb-0"><i class="fas fa-chart-bar"></i> Column</el-radio-button>
                    <el-radio-button :label="1" class="mb-0"><i class="fas fa-chart-bar"></i> Column stacking 1</el-radio-button>
                    <el-radio-button :label="2" class="mb-0"><i class="fas fa-chart-bar"></i> Column stacking 2</el-radio-button>
                    <el-radio-button :label="3" class="mb-0"><i class="el-icon-data-line"></i> Spline</el-radio-button>
                    <el-radio-button :label="4" class="mb-0"><i class="fas fa-chart-line"></i> Area stacking 1</el-radio-button>
                    <el-radio-button :label="5" class="mb-0"><i class="fas fa-chart-line"></i> Area stacking 2</el-radio-button>
                </el-radio-group>

                <el-select v-model="typeOfChart"
                           placeholder="Select" size="medium"
                           class="d-block d-md-none m-auto" style="width: 10rem;"
                           @change="onChangeTypeOfChart">
                    <el-option label="Column" :value="0" />
                    <el-option label="Column stacking 1" :value="1" />
                    <el-option label="Column stacking 2" :value="2" />
                    <el-option label="Spline" :value="3" />
                    <el-option label="Area stacking 1" :value="4" />
                    <el-option label="Area stacking 2" :value="5" />
                </el-select>
            </div>
            <!--<div class="mr-0">
                <el-select v-model="timeCacl"  size="medium" placeholder="Select">
                    <el-option label="Default" :value="1"></el-option>
                    <el-option label="1 Tháng" :value="2"></el-option>
                    <el-option label="3 Tháng" :value="3"></el-option>
                    <el-option label="6 Tháng" :value="4"></el-option>
                </el-select>
            </div>-->
        </div>
    </div>
</template>
<script>
    import chartDataApi from '@/api/common/chartData';
    import { Chart } from 'highcharts-vue';
    //import moment from "moment";
    var numeral = require('numeral');

    const chartTypeArr = [
        { typeChart: 'column', stacking: false },
        { typeChart: 'column', stacking: 'percent' },
        { typeChart: 'column', stacking: 'normal' },
        { typeChart: 'spline', stacking: false },
        { typeChart: 'areaspline', stacking: 'percent' },
        { typeChart: 'areaspline', stacking: 'normal' }
    ]

    export default {
        components: {
            highcharts: Chart
        },
        data() {
            return {
                isLoadingChart: false,
                numberOfDay: 29,
                timeCacl: 1,
                chartTypeArr: chartTypeArr,
                typeOfChart: 0,
                revenueByDateAccordingRechargeTime: {
                    options: {
                        chart: {
                            type: 'column',
                            height: 500
                        },
                        title: {
                            text: 'Top up the account by customer type'
                        },
                        xAxis: {
                            categories: []
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: 'Rate of customer type'
                            }
                        },
                        tooltip: {
                            //pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.total}</b> ({point.percentage:.0f}%)<br/>',
                            formatter: function () {
                                var s = '<b>' + this.x + '</b>',
                                    sum = 0;

                                $.each(this.points, function (i, point) {
                                    sum += point.y;
                                });

                                $.each(this.points, function (i, point) {
                                    s += `<br/>${point.series.name}: $${numeral(point.y).format('0,0')} (${Math.round(100 * point.y / sum)}%)`;
                                });

                                //s += '<br/>Sum: ' + sum

                                return s;
                            },
                            shared: true
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
                                    formatter: function () {
                                        return `$${numeral(this.y).format('0,0')}`;
                                    },
                                    enabled: true
                                },
                                stacking: false,
                            },
                            areaspline: {
                                dataLabels: {
                                    formatter: function () {
                                        return `$${numeral(this.y).format('0,0')}`;
                                    },
                                    enabled: true
                                },
                                stacking: 'percent',
                                lineColor: '#ffffff',
                                lineWidth: 1,
                                marker: {
                                    lineWidth: 1,
                                    lineColor: '#ffffff'
                                },
                            },
                            spline: {
                                dataLabels: {
                                    formatter: function () {
                                        return `$${numeral(this.y).format('0,0')}`;
                                    },
				                    enabled: true
			                    },
                                pointPadding: 0.2,
                                borderWidth: 0
                            }
                        },
                        series: []
                    },
                    tableData: []
                }
            };
        },
        mounted() {
            this.getRevenueByDateAccordingRechargeTime();
        },
        methods: {
            getRevenueByDateAccordingRechargeTime() {
                this.isLoadingChart = true;
                chartDataApi.getRevenueByDateAccordingRechargeTime(this.numberOfDay).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.revenueByDateAccordingRechargeTime.options.xAxis.categories = response.data.data.map(x => x.DateData);
                        this.revenueByDateAccordingRechargeTime.options.series = [{
                                name: "Recharge first time",
                                data: response.data.data.map(x => x.RechargedType1)
                        }, {
                                name: "Recharge again",
                                data: response.data.data.map(x => x.RechargedType2)
                        }, {
                                name: "Recharge more",
                                data: response.data.data.map(x => x.RechargedType3)
                        }];

                        this.isLoadingChart = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoadingChart = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoadingChart = false;
                });               
            },
            onChangeTypeOfChart() {
                this.revenueByDateAccordingRechargeTime.options.chart.type = chartTypeArr[this.typeOfChart].typeChart;

                this.revenueByDateAccordingRechargeTime.options.plotOptions.column.stacking = chartTypeArr[this.typeOfChart].stacking;
                this.revenueByDateAccordingRechargeTime.options.plotOptions.areaspline.stacking = chartTypeArr[this.typeOfChart].stacking;

                if (chartTypeArr[this.typeOfChart].stacking === 'percent') {
                    this.revenueByDateAccordingRechargeTime.options.plotOptions.column.dataLabels.formatter = function () {
                        return `$${numeral(this.y).format('0,0')}<br/>(${Math.round(100 * this.y / this.total)}%)`;
                    };
                    this.revenueByDateAccordingRechargeTime.options.plotOptions.areaspline.dataLabels.formatter = function () {
                        return `$${numeral(this.y).format('0,0')}<br/>(${Math.round(100 * this.y / this.total)}%)`;
                    };
                    this.revenueByDateAccordingRechargeTime.options.plotOptions.spline.dataLabels.formatter = function () {
                        return `$${numeral(this.y).format('0,0')}<br/>(${Math.round(100 * this.y / this.total)}%)`;
                    };
                }
                else {
                    this.revenueByDateAccordingRechargeTime.options.plotOptions.column.dataLabels.formatter = function () {
                        return `$${numeral(this.y).format('0,0')}`;
                    };
                    this.revenueByDateAccordingRechargeTime.options.plotOptions.areaspline.dataLabels.formatter = function () {
                        return `$${numeral(this.y).format('0,0')}`;
                    };
                    this.revenueByDateAccordingRechargeTime.options.plotOptions.spline.dataLabels.formatter = function () {
                        return `$${numeral(this.y).format('0,0')}`;
                    };
                }
            }
        }
    }
</script>